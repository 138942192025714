import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import { kebabCase } from "lodash";
import SlideNav from "./icons/SlideNav";
import PreviewCompatibleImage from "../components/PreviewCompatibleImage";
import { remark } from "remark";
import recommended from "remark-preset-lint-recommended";
import remarkHtml from "remark-html";

const Slides = class extends React.Component {
  constructor(props) {
    super(props);
    this.slidesCont = React.createRef();
  }

  render() {
    const { slides } = this.props;
    return (
      <div className="columns mb-0 flex-reverse-tablet">
        <div className="column is-narrow my-auto">
          <nav className="slides-nav">
            <ul
              style={{ listStyle: "none" }}
              className="ml-0 mt-0 slide-nav-list"
            >
              {slides.map((slide) => (
                <li key={slide.title + `link`} className="slide-nav-list-item">
                  <span
                    className="has-tooltip-arrow has-tooltip-left has-tooltip-hidden-mobile has-tooltip-white"
                    data-tooltip={slide.title}
                  >
                    <Link
                      className="slide-nav-link"
                      to={`#${kebabCase(slide.title)}/`}
                      aria-label={slide.title}
                    >
                      <SlideNav />
                      <span className="is-hidden-tablet v-align-middle is-size-7 ml-1">
                        {slide.title}
                      </span>
                    </Link>
                  </span>
                </li>
              ))}
            </ul>
          </nav>
        </div>
        <div className="column pt-0" style={{ marginTop: "-1rem" }}>
          <div className="slides" id="slides">
            {slides.map((slide) => (
              <div key={slide.title + `slide`} className="slide">
                <h2
                  className="slide-heading title is-size-2 has-text-weight-bold is-bold-light mt-1"
                  id={kebabCase(slide.title)}
                >
                  {slide.title}
                </h2>
                <div className="columns mb-0 pb-6 is-multiline is-centered is-mobile">
                  <div
                    className={
                      slide.image && slide.imageAlign === "right"
                        ? "column is-two-thirds-widescreen is-8-desktop is-12-mobile"
                        : "column is-12"
                    }
                  >
                    <div
                      dangerouslySetInnerHTML={{
                        __html: remark()
                          .use(recommended)
                          .use(remarkHtml)
                          .processSync(slide.content)
                          .toString(),
                      }}
                    />
                    {slide.video ? <iframe title={`${slide.title} YouTube Video`} width="100%" height="auto" style={{aspectRatio: "16/9"}} src={`https://www.youtube.com/embed/${slide.video}`} frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> : null}
                  </div>
                  {/* Slide image w/ link */}
                  {slide.image &&
                    slide.imageLink &&
                    slide.imageAlign === "right" ? (
                    slide.imageAlt ? (
                      <div className="column is-one-third-widescreen is-4-desktop is-8-mobile">
                        <a
                          href={slide.imageLink}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <PreviewCompatibleImage
                            imageInfo={{
                              image: slide.image,
                              alt: slide.imageAlt,
                            }}
                          />
                        </a>
                      </div>
                    ) : (
                      <div className="column">
                        <a
                          href={slide.imageLink}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <PreviewCompatibleImage
                            imageInfo={{
                              image: slide.image,
                              alt: "",
                            }}
                          />
                        </a>
                      </div>
                    )
                  ) : slide.image &&
                    slide.imageLink &&
                    slide.imageAlign === "bottom" ? (
                    slide.imageAlt ? (
                      <div className="column is-12">
                        <a
                          href={slide.imageLink}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <PreviewCompatibleImage
                            imageInfo={{
                              image: slide.image,
                              alt: slide.imageAlt,
                            }}
                          />
                        </a>
                      </div>
                    ) : (
                      <div className="column is-12">
                        <a
                          href={slide.imageLink}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <PreviewCompatibleImage
                            imageInfo={{
                              image: slide.image,
                              alt: "",
                            }}
                          />
                        </a>
                      </div>
                    )
                  ) : null}

                  {!slide.imageLink &&
                    slide.image &&
                    slide.imageAlign === "right" ? (
                    slide.imageAlt ? (
                      <div className="column is-one-third-widescreen is-4-desktop is-8-mobile">
                        <PreviewCompatibleImage
                          imageInfo={{
                            image: slide.image,
                            alt: slide.imageAlt,
                          }}
                        />
                      </div>
                    ) : (
                      <div className="column">
                        <PreviewCompatibleImage
                          imageInfo={{
                            image: slide.image,
                            alt: "",
                          }}
                        />
                      </div>
                    )
                  ) : null}
                  {!slide.imageLink &&
                    slide.image &&
                    slide.imageAlign === "bottom" ? (
                    slide.imageAlt ? (
                      <div className="column is-12">
                        <PreviewCompatibleImage
                          imageInfo={{
                            image: slide.image,
                            alt: slide.imageAlt,
                          }}
                        />
                      </div>
                    ) : (
                      <div className="column is-12">
                        <PreviewCompatibleImage
                          imageInfo={{
                            image: slide.image,
                            alt: "",
                          }}
                        />
                      </div>
                    )
                  ) : null}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
};

Slides.propTypes = {
  Slides: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      imageAlt: PropTypes.string,
      imageAlign: PropTypes.string,
      imageLink: PropTypes.string,
      content: PropTypes.object,
      video: PropTypes.string,
    })
  ),
};

export default Slides;
