import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import '../components/content-post.sass'
import Content, { HTMLContent } from '../components/Content'
import UpdateLinks from '../components/UpdateLinks'
import PreviewCompatibleImage from '../components/PreviewCompatibleImage'
import Slides from '../components/Slides'
import Navbar from '../components/Navbar'
import Faucet from '../components/icons/menu/Faucet'
import ProtectWater from '../components/icons/menu/ProtectWater'
import WaterSupply from '../components/icons/menu/WaterSupply'
import WaterReclaim from '../components/icons/menu/WaterReclaim'
import Conservation from '../components/icons/menu/Conservation'
import Videos from '../components/icons/menu/Videos'

export const ContentPostTemplate = ({
  content,
  contentComponent,
  featuredimage,
  featuredimageAlt,
  slides,
  title,
  helmet,
}) => {
  const PostContent = contentComponent || Content

  return (
    <div className="is-relative">
      <Navbar />
      <section className="section">
        {helmet || ''}
        <UpdateLinks />
        <div className="px-5b px-4-mobile has-background-light pt-3">
          <div className="container content ml-0 mr-auto">
            <div className="content-post-header has-background-light mx-n5b px-5b">
              <div
                className="overlap-previous-section-sm-tablet" style={{ maxWidth: '104px', }}>
                <span className="content-post-featured-image" style={{position: 'relative', display: 'inline-block', }}>
                  {featuredimage && 
                  featuredimage.publicURL &&
                  featuredimage.name !== 'faucet' && 
                    featuredimage.name !== 'protect-water' && 
                    featuredimage.name !== 'water-supply' && 
                    featuredimage.name !== 'water-reclaim' && 
                    featuredimage.name !== 'conservation' && 
                    featuredimage.name !== 'videos' && 
                    <PreviewCompatibleImage
                      imageInfo={{
                        image: featuredimage.publicURL,
                        alt: featuredimageAlt,
                        className: "w-100",
                      }}
                    />
                  }
                  {featuredimage && featuredimage.publicURL && featuredimage.name === 'faucet' && 
                    <Faucet />
                  }
                  {featuredimage && featuredimage.publicURL && featuredimage.name === 'protect-water' && 
                    <ProtectWater />
                  }
                  {featuredimage && featuredimage.publicURL && featuredimage.name === 'water-supply' && 
                    <WaterSupply />
                  }
                  {featuredimage && featuredimage.publicURL && featuredimage.name === 'water-reclaim' && 
                    <WaterReclaim />
                  }
                  {featuredimage && featuredimage.publicURL && featuredimage.name === 'conservation' && 
                    <Conservation />
                  }
                  {featuredimage && featuredimage.publicURL && featuredimage.name === 'videos' && 
                    <Videos />
                  }
                  {featuredimage && !featuredimage.publicURL &&
                    <PreviewCompatibleImage
                      imageInfo={{
                        image: featuredimage,
                        alt: featuredimageAlt,
                        className: "w-100",
                      }}
                    />
                  }
                </span>
              </div>
              <h1 className="is-uppercase has-text-primary has-text-weight-light is-size-5 mt-2">
                {title}
              </h1>
            </div>
            <PostContent content={content} />
            {slides && slides.length ? (
              <Slides slides={slides} />
            ) : null}
          </div>
        </div>
      </section>
    </div>
  )
}

ContentPostTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  title: PropTypes.string,
  featuredimage: PropTypes.object,
  featuredimageAlt: PropTypes.string,
  slides: PropTypes.array,
  helmet: PropTypes.object,
}

const ContentPost = ({ data, location }) => {
  const { markdownRemark: post } = data

  return (
    <Layout location={location}>
      <ContentPostTemplate
        content={post.html}
        contentComponent={HTMLContent}
        description={post.frontmatter.description}
        featuredimage={post.frontmatter.featuredimage}
        featuredimageAlt={post.frontmatter.featuredimageAlt}
        helmet={
          <Helmet titleTemplate="%s | Smart About Water">
            {/* TO DO: add site title to template using query */}
            <title>{`${post.frontmatter.seoTitle}`}</title>
            <meta
              name="description"
              content={`${post.frontmatter.description}`}
            />
          </Helmet>
        }
        slides={post.frontmatter.slides}
        title={post.frontmatter.title}
      />
    </Layout>
  )
}

ContentPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default ContentPost

export const pageQuery = graphql`
  query ContentPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        title
        seoTitle
        description
        featuredimage {
          publicURL
          name
        }
        featuredimageAlt
        slides {
          title
          image {
            childImageSharp {
              gatsbyImageData
            }
          }
          imageLink
          imageAlign
          imageAlt
          content
          video
        }
      }
    }
  }
`
