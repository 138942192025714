import * as React from "react";

function SlideNav(props) {
  const { fill, ...restProps } = props
  return (
    <svg width="1rem" height="1rem" viewBox="0 0 9 12" className="slide-nav-svg" fill={fill} {...restProps}>
      <path
        d="M4.118 0L0 5.797l4.118 5.797 4.117-5.797L4.118 0z"
      />
    </svg>
  );
}

// Set default props
SlideNav.defaultProps = {
  fill: "#b7d6e6",
};

export default SlideNav;